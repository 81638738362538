/**
 *英文语言包
 */
const en = {
  projectTitle: 'BUNKER MANAGEMENT SYSTEM',
  language: 'language',
  loading: 'Loading',
  closeCurrentTab: 'Close current tab',
  closeOtherTabs: 'Close other tabs',
  closeAllTabs: 'Close all tabs',
  closeLeftTabs: 'Close left tabs',
  closeRightTabs: 'Close right tabs',
  refreshCurrentTab: 'Refresh current tab',
  close: 'Close',
  changePsw: 'Change password',
  changeEmail: 'Change email',
  signOut: 'Sign out',
  personalCenter: 'Personal center',
  noConfigureItems: 'There are no items configured in the current system, please configure items.',
  failedToObtainProjectInfo: 'Failed to obtain project information',
  pleaseSelectSearchFiled: 'Please select search field!',
  pleaseEnterSearchContent: 'Please enter search content!',
  sureToExit: 'Are you sure to exit?',
  account: 'Account name',
  originalPsw: 'Original password',
  newPsw: 'New Password',
  confirmPaw: 'Confirm password',
  pswNotMatch: 'The confirmation password does not match the new password',
  originalPswNoEmpty: 'The original password is required',
  newPswNoEmpty: 'The new password is required',
  confirmPswNoEmpty: 'The confirmation password is required',
  pswNoEmpty: 'The password is required',
  pswChangeSuccess: 'Password changed successfully, please log in again',
  pswLength: 'The password should be between 6 and 12 characters long',
  verifyCodeNoEmpty: 'The captcha is required',
  confirmPswInconsistentWithInputPsw: 'The confirmation password is inconsistent with the password input',
  home: 'Home',
  notFindAvailableTab: 'Could not find an available tab',
  selectCurrentProject: 'Please select the current project',
  selectItem: 'Select item',
  add: 'Add',
  add_2: 'Add',
  overview: 'Overview',
  commonStatistics: 'Common statistics',
  customStatistics: 'Custom statistics',
  tableFieldConfiguration: 'Table field configuration',
  tableConfiguration: 'Table configuration',
  addTable: 'Add table',
  tableType: 'Table type',
  status: 'Status',
  assetFieldTemplate: 'Asset field template',
  addField: 'Add field',
  fieldName: 'Field name',
  type: 'Type',
  sort: 'Sort',
  primaryKey: 'Primary key',
  supportForQueries: 'Support for queries',
  operate: 'OPERATE',
  operate2: 'Operate',
  operateLower: 'operate',
  edit: 'Edit',
  delete: 'Delete',
  configurationOfAttachmentStructure: 'Configuration of attachment structure',
  selectTableType: 'Select table type',
  tableName: 'Table name',
  cancel: 'Cancel',
  confirm: 'Confirm',
  fieldType: 'Field Type',
  yes: 'Yes',
  no: 'No',
  support: 'Support',
  updateHistory: 'Update History',
  nonsupport: 'nonsupport',
  fixedOnTheTableHead: 'Fixed on the table head',
  fieldNameCannotBeEmpty: 'Field name is required',
  typePlaceholder: 'Type is required',
  sortAsNumericValues: 'Sort as numeric values',
  addAttachmentTemplateNode: 'Add attachment template node',
  attachmentType: 'Attachment type',
  parentNode: 'Parent node',
  operationSuccess: 'The operation is successful',
  oKToDelete: 'OK to delete?',
  lockUser: 'Lock user',
  unlockUser: 'Unlock user',
  lockUserTip: 'After the user is locked, the user will not have permission to log in to the system. Do you want to continue?',
  unlockUserTio: 'After unlocking the user, the user will restore the corresponding permissions. Do you want to continue?',
  deleteTip_1: 'This node has child nodes, please delete the child nodes first.',
  deleteTip: 'Are you sure you want to delete the data',
  disabledTip: 'Are you sure to disable data？',
  enableTip: 'Are you sure to enable data？',
  tip: 'Tip',
  attachmentTemplateDeletedSuccess: 'Attachment template deleted successfully',
  addedFieldSuccess: 'Added field successfully',
  addTableFieldSuccess: 'Added table field successfully',
  fieldModifiedSuccess: 'Field modified successfully',
  modifyTableSuccess: 'Table modified successfully',
  fieldDeletedSuccess: 'Field deleted successfully',
  tableDeleteSuccess: 'Table deleted successfully',
  addTableTypeSuccess: 'Add table type successfully',
  deleteTableTypeSuccess: 'Delete table type successfully',
  noData: 'No data',
  noDataAlt: 'No data ~',
  refresh: 'Refresh',
  query: 'Query',
  search: 'Search',
  searchOrder: 'Search Order',
  exportExcel: 'Export Excel',
  batchDeletion: 'Batch delete',
  serialNum: 'No.',
  filename: 'File name',
  bizId: 'File business ID',
  bizType: 'File business type',
  invoiceCode: 'Invoice code',
  invoiceNum: 'Invoice number',
  VATInvoiceType: 'Value-added Tax invoice type',
  buyerName: 'Buyer name',
  sellerName: 'Seller name',
  totalPriceAndTax: 'Total price and tax',
  tax: 'Tax amount',
  amount: 'Amount',
  invoiceDate: 'Invoice date',
  verificationTime: 'Verification time',
  verificationMode: 'Verification mode',
  remark: 'Remark',
  view: 'View',
  onlineInspect: 'Online inspect',
  serviceName: 'Service name',
  moreQuery: 'More query',
  generalQuery: 'General query',
  selectDate: 'Select date',
  to: 'to',
  startDate: 'Start Date',
  endDate: 'End Date',
  advancedQuery: 'Advanced query',
  selectQueryField: 'Select query field',
  operator: 'Operator',
  value: 'value',
  and: 'and',
  addCondition: 'Add condition',
  reset: 'Reset',
  or: 'or',
  equal: 'equal',
  greaterThan: 'greater than',
  lessThan: 'less than',
  notEqual: 'not equal',
  includedIn: 'Included in',
  inquiryInfoIncomplete: 'Inquiry information is incomplete!',
  invoicePreview: 'Invoice preview',
  invoiceType: 'Invoice Type',
  submit: 'Submit',
  submitting: 'Submitting...',
  notSupportPreviewTip: 'This type of file preview is not supported, please download it directly!',
  failedGetAttachmentTip: 'Failed to get the attachment. Please try again later!',
  inspectionResult: 'Inspection results',
  true: 'true',
  pleaseSelectAssetTable: 'Please select asset table',
  selectAssetTable: 'Select asset table',
  cityAssetMap: 'City Asset Map',
  projectCompanyRegionalDistributionMap: 'Regional distribution of the project company',
  ARTARank: 'Ranking of accounts receivable transfer amount and invoice amount of project company',
  commonStatisticalClassification: 'Common statistical classification',
  dataResults: 'Data results',
  groupType: 'Group type',
  statisticField: 'Statistic field',
  statisticType: 'Statistic type',
  viewChart: 'View chart',
  totalAssets: 'Total assets',
  approvedTotalOutputVal: 'Approved total output value',
  totalInvoiceAmount: 'Total invoice amount',
  totalAccountsReceivableTransferAmount: 'Total accounts receivable transfer amount',
  assetsSubmittedForReview: 'Assets submitted for review',
  unauditedAssets: 'Unaudited assets',
  approvedNum: 'Approved number',
  notApprovedNum: 'Number of failed audits',
  financingAmountAndProportion: 'Project company financing amount and proportion',
  projectCompany: 'Project Company',
  financingAmount: 'Financing Amount',
  proportion: 'Proportion',
  projectCompanyInvoiceAmount: 'Project company invoice amount',
  invoiceAmount: 'Invoice amount',
  projectCompanyFactoringContractAmount: 'Project company factoring contract amount',
  factoringContractAmount: 'Factoring contract amount',
  receivableTransferAmount: 'Receivable transfer amount',
  addTableType: 'Add Table Type',
  tableFieldTemplate: 'Table field template',
  fieldCode: 'Field code',
  fieldCode_2: 'Field code',
  code: 'Code',
  code_2: 'Code',
  tableIdentificationCode: 'Table identification code, e.g. CONTRACT',
  tableNameCannotBeEmpty: 'Table name is required',
  codeCannotBeEmpty: 'Code is required',
  username: 'Username',
  email: 'Email',
  mobile: 'Mobile',
  disabled: 'Disabled',
  normal: 'Enabled',
  createTime: 'Created At',
  updateTime: 'Updated At',
  modify: 'Modify',
  loginAccount: 'Login account',
  login: 'Log in',
  password: 'Password',
  verificationCode: 'Verification code',
  rememberAccount: 'Remember me',
  forgetPassword: 'Forget password',
  role: 'Role',
  emailFormatError: 'Email format error',
  mobileFormatError: 'Mobile format error',
  usernameCannotBeEmpty: 'The username is required',
  emailCannotBeEmpty: 'Email is required',
  mobileCannotBeEmpty: 'Mobile is required',
  makeSureTo: 'Make sure to',
  perform: 'perform',
  roleName: 'Role name',
  roleCode: 'Role code',
  roleType: 'Role type',
  authorize: 'Authorize',
  roleNameCannotBeEmpty: 'Role name is required',
  name: 'Name',
  nameLower: ' name',
  superiorMenu: 'Superior menu',
  icon: 'Icon',
  menu: 'Menu',
  menuName: 'Menu name',
  routePath: 'Route address',
  componentPath: 'Component address',
  englishName: 'English name of menu',
  enable: 'Enabled',
  lock: 'Deactivate',
  unlock: 'Activate',
  button: 'Button',
  catalog: 'Catalog',
  sortNum: 'Sort number',
  menuUrl: 'Menu Url',
  authorizationMark: 'Authorization mark',
  selectSuperiorMenu: 'Click to select superior menu',
  menuRouter: 'Menu Router',
  menuIcon: 'Menu icon',
  multipleCommas: 'Multiple are separated by commas, such as: user:list,user:create',
  menuIconName: 'Menu icon name',
  menuUrlCannotBeEmpty: 'Menu URL is required',
  superiorMenuCannotBeEmpty: 'Superior menu is required',
  submitReview: 'Submit review',
  batchUpload: 'Batch upload',
  filerColumn: 'Filter column',
  queryByCondition: 'Query by condition',
  clearCondition: 'Clear condition',
  submitQuery: 'Submit query',
  auditStatus: 'Audit Status',
  builtSystemOrg: 'Built in system organization',
  approved: 'Approved',
  approveCompany: 'Approve',
  approveFailed: 'Audit failed',
  approveFailed_2: 'Audit failed',
  initialState: 'Initial state',
  underReview: 'Under review',
  modified: 'Modified',
  annex: 'Attachment',
  annexName: 'Attachment name',
  invoice: 'Invoice',
  failedAuditResults: 'Failed audit results',
  detail: 'Detail',
  reviewTime: 'Review time',
  notBeConfigured: 'This form has not been configured with a table structure, please go to [Table Field Configuration] to configure.',
  selectDataToBeDeleted: 'Please select the data to be deleted',
  selectDataToBeReview: 'Please select the data to be submitted for review',
  fillCorrectValue: 'Please fill in the correct value',
  uploadExcelDataFile: 'Upload Excel data file',
  dragFile: 'Drag the file here, or <em>click to upload</em>',
  excelOnly: 'Excel only!',
  zipOnly: 'Zip only!',
  onlyExcelOnlyCanUpload: 'Only Excel-type files can be uploaded',
  onlyImageCanUpload: 'Tip: Only JPG / PNG image files can be uploaded',
  cannotExceed: 'File size cannot exceed',
  failedToImportData: 'Failed to import data, 0 success, please check【Upload History】',
  partDataImportedSuccess: 'Part of the data imported successfully,',
  total: 'total:',
  successItem: 'success:',
  item: '',
  checkDetail: 'please check [upload history] for details.',
  allImportedSuccess: 'Data imported successfully, ',
  noFailed: 'failed: 0',
  queryQualifiedAsset: 'Query qualified assets according to conditions',
  selectField: 'Select field',
  valueFuzzy: 'Value (fuzzy)',
  addQueryCondition: 'Add query condition',
  resetAll: 'Reset all',
  queryConditionIncorrect: 'The query condition is set incorrectly, please set again',
  excelUploadParseHistory: 'Excel upload/parse history',
  parseRecord: 'Parse records',
  uploadTime: 'Upload time',
  dataNum: 'Data Number',
  successNum: 'Successful Number',
  resultsDesc: 'Results description',
  dataAnalysisDetail: 'Data analysis details (wrong items)',
  dataRow: 'Data row (Excel)',
  uploaded: 'Uploaded',
  uploadedAndCanDelete: 'The file has been uploaded. Please delete the file before uploading again',
  annexList: 'Attachment list',
  viewAllAttachments: 'View all attachments of this type',
  Attachment: 'Attachment',
  batchUploadAttachment: 'Batch upload attachments',
  syncToAsset: 'Please select the asset table to sync to',
  correspondingAsset: 'Corresponding assets',
  fileSize: 'File size',
  preview: 'Preview',
  print: 'Print',
  notSupportedPrint: 'Online printing is not supported for this type of file',
  modifyFileName: 'Modify file name',
  syncAttachment: 'Sync attachment',
  clickToViewSynchronousAttachment: 'Click to view the synchronous attachment',
  templateType: 'Template Type',
  quickSelectMatch: 'Quickly select matches',
  synchronizeSelectedAsset: 'Synchronize selected assets',
  synchronizeAll: 'Synchronize all',
  selectSyncAttachmentMatchCondition: 'Select sync attachment matching conditions',
  selectAssetUploadedOther_1: 'If the selected asset uploaded other[',
  selectAssetUploadedOther_2: ']attachments will be overwritten by this synchronization, do you want to continue?',
  synchronizeAttachmentSuccess_1: 'Successfully synchronize attachments, total:',
  synchronizeAttachmentSuccess_2: '',
  syncTo: 'Sync to ',
  fileNameCannotBeEmpty: 'File name is required',
  fileNameModifyFail: 'File name modification failed',
  selectSyncAttachment: 'Please select attachments to sync',
  deleteFileCannotBeRecovered: 'File cannot be recovered after deletion',
  fileDeleteSuccess: 'File deleted successfully',
  fileDeleteFail: 'File deletion failed',
  attachmentUpload: ' Attachment upload',
  fileTypeNotAllowed: 'This type of file is not allowed',
  onlyZip: 'Only zip type files can be uploaded',
  uploadAttachmentType: 'Only PDF/Word/Excel files and no more than 4M can be uploaded',
  uploadAttachmentPDF: 'Only PDF files and no more than 12M can be uploaded',
  uploadAttachmentPic: 'Only PNG/JPG images can be uploaded, and no more than 4m',
  uploadSalesTitle: 'Please upload sales confirmation',
  selectUploadType: 'Please select the type of file to upload',
  pageExpired: 'Page Expired',
  uploadingZip: 'Uploading Zip package',
  batchUploadSuccess: ' Successfully uploaded attachments in batches',
  invoiceAssociation: 'Invoice association',
  associatedInvoice: 'Associated invoice',
  disassociate: 'Disassociate',
  invoicePlaceHolder: 'Enter the invoice number and press Enter',
  unconnectedInvoice: 'Unconnected invoice',
  relate: 'relate',
  matchBuyerAndSeller: 'Match buyers and sellers',
  atLeastOneInvoice: 'Please select at least one invoice data!',
  warn: 'Warning',
  relatedTip: 'Check that there is inconsistency between the invoice you submitted and the buyer and seller of this asset. Will it continue to be related?',
  invoiceAssociationSuccess: 'Invoice data association succeeded',
  invoiceDisassociatedSuccess: 'Invoice data disassociated successfully',
  querySuccess_1: 'Data query successful,total:',
  querySuccess_2: '',
  switchSuccess: 'Switch success',
  totalAsset: 'Total asset',
  submitted: 'Submitted',
  toBeSubmitted: 'To be submitted',
  submitSuccess: 'Submit data success',
  submitFail: 'Submit failed',
  payable: 'Payable',
  receivable: 'Receivable',
  contractNo: 'Contract No.',
  failureReason: 'Failure reason',
  submitAgainTip: 'click the failure number to submit the data again.',
  dataSubmitCompleted: 'Data submission completed',
  associationStatus: 'Association status',
  connected: 'Connected',
  unlinked: 'Unlinked',
  theme: 'Theme',
  groupCount: 'Group count',
  count: 'Count',
  sum: 'Sum',
  average: 'Average',
  min: 'Min',
  max: 'Max',
  discountRate: 'Discount rate',
  loanAmount: 'Loan amount',
  assetsAudited: 'Assets audited',
  feedbackNum: 'Feedback number',
  date: 'DATE',
  attachmentDownload: 'Attachment download',
  parameterName: 'Parameter name',
  parameterValue: 'Parameter value',
  cannotBeEmpty: ' is required',
  userOperate: 'User operate',
  requestMethod: 'Request method',
  requestParams: 'Request params',
  executionTime: 'Execution time (ms)',
  IPAddress: 'IP address',
  noPageTip: 'Sorry! The page you visited <em>disconnected</em>...',
  duplicateRequestTip: 'Do not repeat the operation!',
  requestError: 'Error. Please try again.',
  backPreviousPage: 'Back to previous page',
  enterHomePage: 'Enter home page',
  layoutSetting: 'Layout settings',
  navBarType: 'Navigation bar type',
  sidebarSkin: 'Sidebar skin',
  default: 'Default:',
  welcomeToLogin: 'Login to your Account',
  welcome: 'Welcome',
  messageBox: 'Message box',
  desc: 'Description',
  userList: 'User list',
  roleList: 'Role list',
  selectUser: 'Please select user!',
  userRoleConfigSuccess: 'User role configured successfully',
  auditPoint: 'Audit points',
  auditPointLower: ' audit points',
  selectRole: 'Please select role!',
  privileges: 'Privileges',
  permissionList: 'Permission list',
  rolePermissionConfigSuccess: 'Role permissions configured successfully',
  assetClassification: 'Asset classification',
  auditPointsConfiguration: 'Audit points configuration',
  import: 'Import',
  export: 'Export',
  auditCategory: 'Audit category',
  categoryManage: 'Category management',
  pleaseSelect: 'Please select',
  selectCompanyTeam: 'Switching company and team',
  switchCompanySuccess: 'Successful switch between enterprise and team',
  pleaseEnterContent: 'Please enter content',
  rejectBeEmpty: 'This field is required',
  save: 'Save',
  acknowledge: 'Acknowledge',
  auditCategoryManage: 'Audit category management',
  uploadFile: 'Upload File',
  uploadImage: 'Upload image',
  downloadTemplate: 'Download template',
  importingData: 'Importing data...',
  batchImport: 'Batch import',
  batchImportSuccess: 'Batch import is successful!',
  exportField: 'Export field',
  selectAssetTableFirst: 'Please select assets table first',
  auditPointImportTemplate: 'Audit points import template',
  tableFileSelect: 'Table field selection',
  assetData: 'Asset data',
  assetTable: 'Asset table',
  push: 'Push',
  auditOverview: 'Audit overview',
  pendingAudit: 'Pending audit',
  auditNotPass: 'Audit not passed',
  auditInstruction: 'Audit Instructions',
  addAuditCategories: '[All audit categories]',
  showAll: 'Show all',
  assetApproveEnterPool: 'The asset has been reviewed and approved, it is recommended to enter the pool!',
  assetUnderReview: 'The asset is under review!',
  assetNotReview: 'The asset has not been reviewed yet!',
  auditBeApproved: 'The audit classification has been approved!',
  auditCategoryNotReview: 'This review category is not yet reviewed!',
  keyPointFail: 'Key points for failure',
  overallAuditStatus: 'Overall audit status',
  auditHistory: 'Audit history',
  businessAttachmentDisplayArea: 'Business document attachment level display area',
  businessAttachmentArea: 'Business document attachment management area',
  attachmentToThisResource: 'Attachment to this resource',
  download: 'Download',
  operationType: 'Operation type',
  operationResult: 'Operation result',
  feedbackType: 'Feedback type',
  instructions: 'Instructions',
  operateMechanism: 'Operating mechanism',
  operatorP: 'Operator',
  operationTime: 'Operation time',
  audit: 'Audit',
  assetAudit: 'Asset audit',
  auditCategoryAndKeyPoints: 'Audit category and key points',
  totalNum: 'Total',
  audited: 'Audited',
  unaudited: 'Unaudited',
  unPassed: 'unpassed',
  more: 'More',
  collapseAll: 'Collapse all',
  expandAll: 'Expand all',
  temporaryStorageData: 'Temporary storage data',
  recoverData: 'Recover data',
  oneClickAudit: 'One-click audit',
  noAuditPoint: 'No audit points',
  enterDescription: 'Enter description',
  switchAttachment: 'Switch attachment',
  selectAttachment: 'Select attachment',
  current: 'Current',
  switch: 'Switch',
  loginAgainAfterRoleChange: 'You are modifying your permissions. You will login again after submitting successfully!',
  relogin: 'Relogin',
  noNeedTemporary: 'There is no audit data, no need to temporarily store it!',
  canRestoreData: 'The audit data is temporarily saved, you can use the [Restore Data] button to restore!',
  recoverSuccess: 'Audit data recovery is successful!',
  noLocalAsset: 'No audit data for the current asset is found locally!',
  unauditedPoint: 'Detected unaudited points!',
  unfilledAudit: 'It is detected that there are unfilled audit instructions',
  submitAuditTip: 'Are you sure to submit audit data?',
  submitPermissionTip: 'Are you sure to save permission data?',
  auditSubmitSuccess: 'Audit data submitted successfully!',
  attachmentPreview: 'Attachment preview',
  feedback: 'Feedback',
  all: 'All',
  pushed: 'Pushed',
  unPushed: 'Unpushed',
  selectAtLeastOneData: 'Please select at least one piece of data!',
  pushSuccess: 'Push successful!',
  generatedNewAttachment: 'A new attachment package has been generated. Click download',
  dataTemplate: 'Data template',
  attachmentTemplate: 'Attachment template',
  uploadData: 'Upload data',
  uploadAttachment: 'Upload Attachment',
  uploadHistory: 'Upload history',
  columnDisplaySetting: 'Column display settings',
  attachment: 'Attachment',
  pleaseEnterCategoryC: 'Please enter category content',
  addCategory: 'Add category',
  enterAuditCategoryFirst: 'Please enter the audit category first!',
  selectSynchronizedAsset: 'Select assets to be synchronized, attachment type:',
  upload: 'Upload',
  rotate: 'Rotate',
  selectPackagedAsset: 'Select packaged assets',
  statusDesc: 'Status description',
  fieldNamePlaceholder: 'Field name, such as contract name',
  fieldCodePlaceholder: 'Field code, such as contract name corresponding to CONTRACT_NAME or HETONGMINGCHENG',
  fieldCodePlaceholder_2: 'Such as: contract_name (id, parent_id, sys_stamp are reserved by the system, please do not use)',
  pushStatus: 'Push status',
  underReview_2: 'Under review',
  dataModifySuccess: 'Data modify successfully!',
  dataAddSuccess: 'Data add successfully!',
  atLeastSelectOneData: 'Please select at least one piece of data to be deleted!',
  deletePermanent: 'This operation will permanently delete the data. Do you want to continue?',
  dataDeleteSuccess: 'Data delete successfully!',
  dataDisabledSuccess: 'Data disabled successfully!',
  dataEnableSuccess: 'Data enable successfully!',
  currencyUnit: ' million',
  selectAsset: 'Please select the asset to view first',
  name_2: ' name',
  totalNum_2: 'Total',
  applyFinancingInstitution: 'apply financing institution ',
  financeApplicationNo: 'Financing application No',
  totalAmountTrade: 'Total amount of trade',
  intentionFinanceAmount: 'Intentional financing amount',
  intentionFinanceRate: 'Intentional financing interest rate(%)',
  financePeriod: 'Financing period (day)',
  financeInterest: 'Financing interest',
  financeApplicationDeadline: 'Financing application valid deadline',
  factoringType: 'Factoring type',
  openInsurance: 'Open insurance',
  secretInsurance: 'Secret insurance',
  preReview: 'Preliminary review',
  review: 'Review',
  allFinancingList: 'All financing list',
  noFeedback: 'No feedback',
  feedbackNotConfirmed: 'Feedback not confirmed',
  feedbackConfirmed: 'Feedback confirmed',
  submitForReview: 'Submitted for review',
  AuthenticationCenterUser: 'Authentication center user',
  addUsername: 'Add username',
  tablePermissionList: 'Table permission list',
  pleaseEnter: 'Please enter',
  saveSuccess: 'Data saved successfully',
  importSuccess: 'Import succeeded',
  importFail: 'Import failed',
  downloadXmlTemplate: 'Download xml template',
  downloadJsonTemplate: 'Download json template',
  createTable: 'Create table',
  fieldAlias: 'Field alias',
  unique: 'Unique',
  showDateOnly: 'Show date only',
  required: 'Required',
  calculationFormula: 'Calculation formula',
  sourceField: 'Source field',
  mainTable: 'Main table',
  childTable: 'Child table',
  relateSourceField: 'Relate source field',
  selectedField: 'Selected field',
  relatedField: 'Related field',
  selectTargetForm: 'Select target form',
  notRequired: '(not required)',
  quickSelectField: 'Enter # to quickly select the field',
  formulaOperatorTip: 'Tips:Formula operator tips ',
  sureToDisassociate: 'Sure to disassociate?',
  disassociateSuccess: 'Disassociate successfully',
  selectMainTable: 'Select main table',
  associateChildTableNum: 'Number of associated child tables',
  associateChildTable: 'Associated Child Table',
  childTableList: 'Child table list',
  selectChildTable: 'Select child table',
  sourceDocument: 'Source document',
  documentName: 'Document name',
  targetDocumentQty: 'Target document quantity',
  upstreamDocumentQty: 'Upstream document quantity',
  associateTargetDocument: 'Associated target document',
  relateTargetDocument: 'Relate target document',
  targetDocumentName: 'Target document name',
  conversionField: 'Field conversion between source document and target document',
  sourceDocumentField: 'Source document field',
  targetDocumentField: 'Target document field',
  sourceAssociateAndTargetList: 'List of fields associated with source document and target document',
  sourceDocumentFieldName: 'Source document field name',
  targetDocumentFieldName: 'Target document field name',
  selectTargetDocument: 'Select target document',
  sureToDisassociateAndDeleteData: 'Disassociate will also delete the data link. Are you sure?',
  associateFail_1: 'Failed to associate ',
  associateFail_2: ', please add a unique field to the document in the table field configuration.',
  viewName: 'View name',
  boundViewsReportList: 'Bound views report list',
  reportName: 'Report name',
  remove: 'Remove',
  viewAndReportNameBound: 'View and report name bound',
  addReportSuccess: 'Report added successfully',
  sureToRemove: 'Are you sure to remove?',
  removeReportSuccess: 'Report removed successfully',
  funded: 'Funded',
  unfunded: 'Unfunded',
  asset: 'Asset',
  financingStatus: 'Financing status',
  back: 'Back',
  auditResult: 'Results of audit',
  resultInterpretation: 'Interpretation of results',
  colorSchemes: 'Color schemes',
  organizationName: 'Organization name',
  organizationCode: 'Organization code',
  organization: 'Organization',
  department: 'Department',
  principal: 'Principal',
  superiorOrganization: 'Superior organization',
  functionName: 'Function name',
  functionCode: 'Permission code',
  functionList: 'Function list',
  superiorFunction: 'Superior function',
  rootMenu: 'Top-level menu',
  flowChart: 'Approval flow chart',
  startReview: 'Start review',
  waitingTask: 'Waiting tasks',
  taskId: 'Task ID',
  currentProcess: 'THE CURRENT PROCESS',
  taskDetails: 'Task details',
  approvalHistory: 'Approval history',
  applyUpdate: 'Application for amendment',
  updateAuditing: 'Pending Approval',
  applyTip: 'Are you sure to apply?',
  agree: 'Agree',
  disagree: 'Disagree',
  approver: 'Approver',
  approverInstruction: 'Instructions',
  approvalComments: 'Please enter your approval comments',
  saveAndInitiateApproval: 'Save and initiate approval',
  saveAndInitiateApprovalSuccess: 'Save and initiate approval successfully, to be processed by the next process reviewer',
  initiateApproval: 'Initiate approval',
  dismissApproval: 'Rejected, the approval process returns to the original node',
  approve: 'Approved, to be processed by the next process reviewers',
  initiateApprovalTip: 'Are you sure to initiate approval?',
  businessData: 'Business data',
  businessId: 'Business id',
  tableDesc: 'Table Description',
  basicInformation: 'BASIC INFORMATION',
  notApproval: 'The approval process has not started yet',
  confirmContinue: 'Are you sure to confirm this document?',
  checkTips: 'Error. Required fields not filled in.',
  noApprovalHistory: 'No approval history',
  noCreateTime: 'No create time',
  authCode: 'Auth code',
  getAuthCode: 'Get auth code',
  sureAgree: 'Are you sure agree?',
  sureReject: 'Are you sure reject?',
  reject: 'Reject',
  rejectUpdate: 'Reject Update',
  agreeUpdate: 'Approve Update',
  agreed: 'Agreed',
  rejected: 'Rejected',
  agreeOrNot: 'AGREE OR NOT',
  processDefinitionKey: 'Flow key',
  previous: 'Previous',
  next: 'Next',
  getCode: 'Get code',
  passwordResetSuccessful: 'Password reset Successful',
  inputUserInfo: 'Enter user information',
  resetPassword: 'Reset password',
  emailLogin: 'Email login',
  mobileLogin: 'Mobile phone login',
  noActiveCompany: 'The enterprise activated by the current user is not found, please contact the administrator',
  noDefaultTeam: 'Default team is not found',
  hasUserTip: 'Tip: if the same e-mail already exists in the system, the account information corresponding to this e-mail will be reset to the current submitted information (including password).',
  authenticate: 'Edit CA certificates',
  confirmAuthenticate: 'Are you sure to conduct enterprise certification?',
  waitAuthenticateSuccess: 'Submit the authentication successfully, please wait for the authentication result',
  pass: 'pass',
  licenseNumber: 'License number',
  bunkerchainCa: 'Bunkerchain CA',
  netrustCa: 'Netrust CA',
  setCA: 'Set CA',
  inputAuthenticationCode: 'Please enter the CA certificates',
  CASetSuccess: 'CA certificate set successfully!',
  queryCondition: 'Query condition',
  authenticationSuccess: 'Enterprise Certification successful',
  ip: 'Ip',
  title: 'Title',
  requestUri: 'Request url',
  method: 'Request method',
  exception: 'exception',
  operateDate: 'Operate time',
  youAre: 'You are the hero and dream of ordinary life, Lan Ning.',
  userId: 'User id',
  noFlowForm: 'There was an error and no corresponding document for this process was found',
  flow: 'Flow',
  node: 'Node',
  user: 'User',
  uploader: 'Uploader',
  acceptor: 'Acceptor',
  bindingMariner: 'Binding mariner',
  onlyOneUseRightBtn: 'To bind only one approver, use the right binding button',
  blockChainInfo: 'Blockchain info',
  selectCompany: 'Please select the company first',
  selectDepartment: 'Please select the department first',
  selectTeam: 'Please select the team first',
  selectOrder: 'Please select the order first',
  refreshSuccess: 'Data refresh successfully',
  company: 'Company',
  team: 'Team',
  addTeam: 'Add team',
  editTeam: 'Edit team',
  deleteTeam: 'Delete team',
  inputTeamName: 'Please input team name',
  invite: 'Invite',
  inviteUser: 'Invite User',
  inviteCompany: 'Invite Company',
  rejectCompanyRegistration: 'Reject company registration',
  approveCompanyRegistration: 'Approve company registration',
  acceptCompanyUpdate: 'Accept company update',
  activateCompany: 'Activate company',
  deactivateCompany: 'Deactivate company',
  deleteCompany: 'Delete company',
  deleteUser: 'Delete user',
  editPermission: 'Edit permission',
  deleteShip: 'Delete ship',
  deleteFile: 'Delete file',
  flag: 'Flag',
  unflag: 'Unflag',
  inviteCompanyAdmin: 'Invite a Company Administrator',
  inviteCompanyManager: 'Invite a Company Manager',
  inviteTeamManager: 'Invite team manager',
  inviteTeamMember: 'Invite a Team Member',
  invitedMailSentSuccess: 'Invitation email has been sent.',
  transfer: 'Transfer',
  chooseTransferTeam: 'Choose to transfer to team',
  agreeUpdateCompany: 'Are you sure you agree to modify the company information?',
  rejectReasonTip: 'Please enter the reason for rejection',
  checkAll: 'Check all',
  startTransfer: 'Start Transfer',
  transferTo: 'Transfer To',
  transferredToOrFrom: 'Transferred To/From'
}

const enterpriseRegister = {
  enterpriseRegister: 'Company Registration',
  companyInfo: 'Company Information',
  companyBaseInfo: 'Basic information of enterprise',
  contactInfo: 'Contact information',
  adminInfo: 'Administrator Information',
  financerInfo: 'Financer Information',
  companyDocument: 'Company Documents',
  enterpriseName: 'Corporate registration',
  companyName: 'Company name',
  businessIncorporationNumber: 'UEN/Business Incorporation No',
  bdnEmailTitle: 'UEN/Business Incorporation No',
  incorporationCountry: 'Country of Incorporation',
  streetName: 'Street Name',
  buildingName: 'Building Name',
  unitNumber: 'Unit Number',
  cityOrState: 'City/State',
  fax: 'Fax',
  publicKey: 'Public Key',
  apiKey: 'API Key',
  apiName: 'API Name',
  companyType: 'Company Type',
  designation: 'Designation',
  businessIncorporationDocument: 'Business Incorporation Document or ACRA',
  authorizationLetter: 'Authorization Letter (If not company director)',
  operatorLicences: 'MPA Bunker Licences',
  bunkerLicences: 'MPA Bunker Licences',
  craftOperatorLicences: 'MPA Craft Operator Licences',
  bdnLogo: 'Company Logo',
  otherDocument: 'Other Documents',
  firstName: 'First Name',
  lastName: 'Last Name',
  salutation: 'Salutation',
  homePhoneNumber: 'Contact Number',
  officePhoneNumber: 'Office Phone Number',
  duplicatePassword: 'Confirm Password',
  verificationCode: 'Verification Code',
  clickVerifyEmail: 'Verify Email',
  codeObtainedSuccessfully: 'A verification code has been sent to the registered email address.',
  emailVerified: 'Email has been verified',
  readAndAgree: 'Read and agree',
  register: 'Register',
  acceptRegister: 'Agree Registration',
  acceptRegisterTip: 'Approve company registration?',
  refuseRegister: 'Disagree Registration',
  existingAccount: 'Is there any account?',
  loginImmediately: 'Sign In Instead',
  verify: 'Verify',
  registrationAgreement: 'Corporate registration agreement of Bunker Chain',
  inputEmail: 'Please enter your email address',
  inputCode: 'Please enter a verification code',
  verifyTipFront: 'The verification code has been sent to the email address',
  verifyTipEnd: '. Please check and input the verification code',
  emailPassed: 'The email verification passed',
  emailFailed: 'The email verification failed',
  readAndAgreeAgreement: 'Please read and agree to the registration agreement first',
  registerSuccess: 'Enterprise administrator registered successfully, please log in and improve the enterprise information',
  registerSuccess2: 'Register was successful!',
  jump2login: 'Jump to login',
  enterprisesInfo: 'Company info',
  group: 'Group',
  incorporationNos: 'Uen or biz incorporation nos',
  incorporationDate: 'Incorporation Date',
  address: 'Address',
  city: 'City',
  state: 'State',
  postal: 'Postal / Zip Code',
  contactPerson: 'Contact person',
  position: 'position',
  phone: 'Phone',
  telephone: 'telephone',
  enterpriseType: 'Enterprise type',
  bargesCount: 'Barges count',
  vesselCount: 'Vessel count',
  surveyorCount: 'Surveyor count',
  businessLicense: 'Business license',
  bankInfo: 'Bank info',
  bankName: 'Bank name',
  branch: 'Branch',
  account: 'Account nos',
  ship: 'Ship',
  loginable: 'Create a Ship Account',
  accountType: 'Account type',
  vesselInfo: 'Vessel info',
  owner: 'Owner',
  shipManager: 'Ship managers',
  charterer: 'Charterers',
  tankCount: 'No of tank',
  tankRank: 'Tank rank',
  imo: 'Imo no',
  status: 'Status',
  registryPort: 'Port of Registry',
  callSign: 'Call Sign',
  vesselName: 'Vessel name',
  exName: 'Ex Name (if any)',
  sealVerificationReportNo: 'Seal Verification Report No.',
  meterSerialNo: 'Meter Serial No.',
  stampId: 'Ship Stamp',
  vesselType: 'Vessel type',
  subClass: 'Sub class',
  licenceNo: 'License No.',
  cellPhone: 'Cell phone',
  locationLevel: 'Location',
  mfmIpAddress: 'MFM IP Address',
  location: 'Location',
  satellitePhone: 'Satellite phone',
  buildYear: 'Year of built',
  majorApproved: 'Major approved',
  shipClass: 'Ship class',
  ownershipStatus: 'Ownership status',
  subStatus: 'Sub status',
  shipContact: 'Ship contact',
  hp: 'H/P',
  charterPartyEnd: 'Charter party end',
  marinerInfo: 'Mariner info',
  nationality: 'Nationality',
  identity: 'Identity',
  representCompany: 'Represent company',
  attachmentName: 'Attachment name',
  enterAttachmentName: 'Please enter the attachment name',
  uploadAttachmentTypeEnterprise: 'Only PDF/JPG files and no more than 4M can be uploaded',
  uploadSuccess: 'Attachment uploaded successfully',
  saveSuccessAndUpload: 'Data saved successfully, you can continue to improve the attachment',
  waitPerfect: 'Wait for the perfect',
  waitReview: 'Wait for review',
  waitCertification: 'Wait for certification',
  certificationPass: 'Certification passed',
  certificationReject: 'Certification rejected',
  noState: 'No state',
  CARGO_SELLER: 'CARGO_SELLER',
  BUNKER_TRADER: 'BUNKER_TRADER',
  FINANCING_INSTITUTION: 'FINANCING_INSTITUTION',
  SHIP_OWNER: 'SHIP_OWNER',
  SURVEYORS: 'SURVEYORS',
  BUNKER_SUPPLIER: 'BUNKER_SUPPLIER',
  chooseVesselFirst: 'Please choose the vessel first',
  singapore: 'Singapore',
  china: 'China',
  chinaTanwan: 'China(Taiwan)',
  chinaHongKong: 'China(Hong Kong)',
  chinaMacau: 'China(Macau)',
  otherState: 'Other State',
  duplicateCompanyTip: 'The same company already exists in the system. Duplicate registration is not allowed!',
  duplicateCompanyUENTip: 'The same company UEN already exists in the system. Duplicate registration is not allowed!',
  duplicateEmail: 'The same email account already exists in the system. If you continue to register, the account information corresponding to this email account will be used. Do you want to continue?',
  duplicateWarning: 'Duplicate warning',
  bdnEmailTitle: 'BDN Email Title'
}
const bankInfo = {
  bank_name: 'Name of Bank',
  bank_swiftCode: 'Swift Code',
  bank_accountNo: 'Account Number',
  bank_accountType: 'Account Type',
  bank_country: 'Country'
}

const menus = {
  MY_ENTERPRISES: 'My Enterprises',
  HOME: 'Home',
  SYSTEM_MANAGEMENT: 'System Administration',
  MENU_MANAGEMENT: 'Menu Management',
  ORGANIZATION_MANAGEMENT: 'Organization Management',
  COMPANY_MANAGEMENT: 'Company Management',
  DEPARTMENT_MANAGEMENT: 'Department Management',
  TEAM_MANAGEMENT: 'Team Management',
  ROLE_MANAGEMENT: 'Role Management',
  USER_MANAGEMENT: 'User Management',
  FUNCTION_MANAGEMENT: 'Function Management',
  PERMISSION_MANAGEMENT: 'Permission Management',
  SYSTEM_SETTING_MANAGEMENT: 'Setting Management',
  BUSINESS_MANAGEMENT: 'Business Management',
  SHIP_MANAGEMENT: 'Ship Directory',
  SYSTEM_LOG: 'System Log',
  FILE_MANAGEMENT: 'File management',
  EXPERIMENTAL_FUNCTION: 'Experimental function',
  AUDIT_LOGGING: 'Audit logging',
  REQUEST_LOGGING: 'Request logging',
  DATA_CHANGE_LOGGING: 'Data change logging',
  WHITELIST_MANAGEMENT: 'Credit & Risk Management',
  MASTER_CONTRACT_MANAGEMENT: 'Contract Management',
  MASTER_CONTRACT_CONFIRMTION: 'Contract Management Confirmtion',
  PURCHASE_ORDER_MANAGEMENT: 'Confirmation Of Purchase Management',
  SALES_ORDER_MANAGEMENT: 'Bunker Sales Creation',
  SHIPOIL_ORDER_MANAGEMENT: 'Ship Oil Order Management',
  TRADERS: 'Bunker Traders',
  FREIGHTER_COMPANY: 'Shipping Liners',
  BARGE_COMPANY: 'Bunker Suppliers',
  PURCHASE_ORDER: 'Purchase Order Creation',
  PURCHASE_ORDER_A: 'Ex-wharf Order',
  PURCHASE_ORDER_CONFIRMTION: 'Purchase Order Confirmation',
  PURCHASE_ORDER_CONFIRMTION_A: 'Bunker Purchase Confirmation',
  SALES_ORDER_CONFIRMTION: 'Final Quantity Confirmation',
  ARRIVE_OPERATION_DETAILS: 'Arrive operation details',
  BUNKER_SALES_ORDER: 'Stem Creation',
  OIL_SUPPLY_COMPANY: 'Cargo Suppliers',
  FINANCING_APPLICATION_MANAGEMENT: 'Financing Management',
  FINANCING_APPLICATION_CONFIRMATION: 'Financing application confirmation',
  BARGE_FILLING_PROGRAM_MANAGEMENT: 'Ex-wharf Nomination',
  BARGE_DISTRIBUTION_OIL_PROGRAM: 'Barge Scheduling',
  IOT_BUNKER_LOADING_DATAS: 'Oil separation monitoring',
  FUNDING_AGENCY: 'Financial Institutions',
  INVOICE_MANAGEMENT: 'Create Invoice',
  INVOICE_CONFIRMTION: 'Invoice Receipt',
  INVOICE_APPLICATION: 'Invoice application & confirmation',
  RECEIPT_CONFIRMTION: 'Payment Receipt Confirmation',
  PAYMENT_SLIP: 'Payment Confirmation',
  PAYMENT_RECEIPT_SLIP: 'Payment Receipt Confirmation',
  FINANCING_RECEIPT: 'Financing Receipt Slip',
  SALES_APPLICATION_FORM: 'sales Request Application',
  SALES_APPLICATION_FORM_AUDIT: 'Audit sales Request Application',
  OFFLINE_MODE: 'Offline mode',
  order: 'Order',
  orderId: 'Order id',
  orderType: 'Order Type',
  exWharf: 'Ex_wharf',
  relatedOrder: 'Upstream order link',
  counterPartyEmail: 'Email to counter party',
  MENU_ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
  MENU_ACCOUNTS_RECEIVABLE_POSTED_LIST: 'Posted List',
  MENU_ACCOUNTS_RECEIVABLE_INVOICE: 'Invoice',
  MENU_ACCOUNTS_RECEIVABLE_NOTE: 'Credit/Debit Note',
  MENU_ACCOUNTS_PAYABLE: 'Accounts Payable',
  MENU_ACCOUNTS_PAYABLE_INVOICE: 'Invoice',
  MENU_ACCOUNTS_PAYABLE_NOTE: 'Credit/Debit Note',
  MENU_ACCOUNTS_PAYABLE_NOTE_TEST: 'CD Note Test',
  MENU_ORDER_DELIVERED: 'Delivered',
  MENU_ORDER_EX_WHARF: 'Ex-Wharf',
  MENU_TRADE_ADMIN: 'Trade Administration',
  MENU_TRADE_ADMIN_CONTRACT: 'Contract Management',
  MENU_ORDER_BARGING_NOMINATION: 'Barging Nomination',
  MENU_TRADE: 'Trade'
}

const businessAttachment = {
  CONTRACT: 'Contract',
  ORDER: 'Order',
  INVOICE: 'Invoice',
  COLLECTION_ORDER: 'Collection order',
  FINANCING_APPLICATION_ORDER: 'Financing application order',
  SALES_APPLICATION_ORDER: 'Sales application order',
  SALES_ORDER: 'Sales order',
  PAYMENT_ORDER: 'Payment order',
  FINANCING_CONTRACT: 'Financing contract',
  OIL_DISTRIBUTING_PLAN: 'Oil distributing plan',
  LOADING_PLAN: 'Loading plan'
}

const users = {
  fullname: 'Fullname',
  job: 'Job',
  jobTitle: 'Job title',
  technicalPost: 'Technical post',
  managementPost: 'Management post',
  handleRoles: 'Handle roles',
  currentRoles: 'Current roles',
  optional: 'Optional',
  selected: 'Selected',
  bound: 'Bound',
  binding: 'Binding',
  unbinding: 'Unbinding',
  confirm: 'Confirm',
  okToBind: 'Ok to bind?',
  deleteSuccess: 'Deleted successfully',
  roleBindingFailed: 'Role binding failed',
  selectDataFirst: 'Please select data first',
  singleSelection: 'Please single selection only',
  gender: 'Gender',
  male: 'Male',
  female: 'Female'
}

const roles = {
  administrator: 'Administrator',
  operator: 'Operator'
}

const masterContract = {
  masterContract: 'MASTER CONTRACT',
  masterContractInfo: 'MASTER CONTRACT INFORMATION',
  closeContract: 'Close contract',
  creditInfo: 'CREDIT INFORMATION',
  basicInfo: 'BASIC INFORMATION',
  vesselInfo: 'VESSEL INFORMATION',
  remarksInfo: 'REMARKS INFO',
  contractType: 'Contract Type',
  contractMode: 'Contract mode',
  pricingType: 'Pricing type',
  datetime: 'Datetime',
  contractDate: 'Contract date',
  pickUp: 'Lifting',
  port: 'Port',
  sellerInCharge: 'Seller in charge',
  contacts: 'Contact No',
  creditTerm: 'Credit term',
  creditLine: 'Credit limit',
  creditLineUse: 'Credit use',
  creditLineSurplus: 'Credit balance',
  totalContractValue: 'Contract total value',
  laydaysStart: 'LAYCAN start',
  laydaysEnd: 'LAYCAN end',
  specs: 'Specs',
  pickUpQuantity: 'Lifting lots',
  maxPickupTimes: 'Balance lots',
  totalQuantity: 'Total quantity',
  surplus: 'Surplus',
  operatingTolerance: 'Operational tolerance',
  pickUpMin: 'Min quantity lifting',
  pickUpMax: 'Max quantity lifting',
  unit: 'Unit',
  gradesAndSpecs: 'REQUISITED GRADES & SPECS',
  minQuantity: 'Quantiry MIN',
  maxQuantity: 'Quanity MAX',
  buyingPrice: 'Buy price',
  sellingPrice: 'Sell price',
  totalPrice: 'Total value',
  downstream: 'Downstream',
  remarks: 'Remarks',
  buyerRemark: 'Buyer remarks',
  sellerRemark: 'Seller remarks',
  usageRecord: 'Contract execution record',
  orderNo: 'Order No.',
  pickupConditions: 'Lifting conditions',
  pickUpDate: 'Lifting date',
  oilName: 'Product name',
  pickUpValue: 'Lifting value',
  imoNotRequired: 'IMO',
  pickupNoticeDays: 'Lifting notice days',
  declareDate: 'Declared date',
  sellerHandler: 'Seller handler',
  ACCOUNTS_PAYABLE: 'Finance & Accounting Accounts Payable',
  ACCOUNTS_RECEIVABLE: 'Finance & Accounting Accounts Receivable',
  FLOW_MANAGEMENT: 'Flow management',
  viewEBDN: 'View EBDN Document'
}

const whiteListManagement = {
  whitelistCompany: 'Whitelist company',
  clientInformation: 'CLIENT INFORMATION',
  amountInformation: 'THE AMOUNT OF INFORMATION',
  quotaChangeHistory: 'QUOTA CHANGE HISTORIES',
  supplierName: 'SUPPLIER NAME',
  enterpriseGroup: 'GROUP',
  supplierAddress: 'SUPPLIER ADDRESS',
  supplierPic: 'SUPPLIER PIC',
  supplierContact: 'SUPPLIER CONTACT',
  supplierEmail: 'SUPPLIER-MAIL (GENERAL)',
  enterpriseEmail: 'Enterprise email',
  personalEmail: 'Personal email',
  email: 'E-MAIL',
  creditLineAllocation: 'CREDIT LINE ALLOCATION',
  currency: 'CURRENCY',
  creditType: 'CREDIT TYPE',
  longestPaymentDays: 'THE LONGEST SINGLE STROKE IS PAYMENT DAYS',
  approvalDate: 'DATE OF CREDIT APPROVAL',
  lastCreditAllocation: 'LAST CREDIT ALLOCATION',
  creditExtensionDate: 'CREDIT EXTENSION DATE',
  extendedApplicantPosition: 'EXTENDED APPLICANT POSITION',
  extendedApplicantName: 'EXTENDED APPLICANT NAME',
  nextReviewDate: 'NEXT REVIEW DATE',
  balance: 'BALANCE',
  availableCredit: 'AVAILABLE CREDIT',
  quotaApprovalInfo: 'QUOTA APPROVAL INFORMATION',
  assets: 'ASSETS',
  shipName: 'Ship Name',
  imo: 'Imo',
  grt: 'GRT',
  builtYear: 'YEAR OF BUILT',
  vesselAge: 'AGE',
  fuelType: 'TYPE',
  vesselSize: 'SIZE',
  hullClass: 'HULL CLASS',
  majorApproved: 'MAJOR APPROVED',
  ownership: 'OWNERSHIP',
  gradesSupplied: 'GRADES SUPPLIED',
  sulphur: 'SULPHUR',
  grade: 'GRADE',
  pastStems: 'PAST STEMS',
  stemNumber: 'STEM NUMBER',
  deliveryDate: 'VESSEL NAME',
  creditTerms: 'CREDIT TERMS',
  grades: 'GRADES',
  quantity: 'QUANTITY (MT)',
  buyPrice: 'BUY PRICE',
  amount: 'AMOUNT (USD)',
  paymentDate: 'PAYMENT DATE',
  agingReport: 'AGING REPORT',
  uploadSupportFiles: 'Upload support files',
  procStatus: 'STATUS OF APPROVAL',
  approvalNotBegun: 'Approval has not begun',
  EXECUTE: 'Pending Approval',
  FINISHED: 'Approval to complete',
  amountCreationDate: 'AMOUNT CREATION DATE',
  getApprovedDate: 'GET APPROVED DATE',
  quotaType: 'QUOTA TYPE',
  creditAmount: 'CREDIT AMOUNT',
  creator: 'CREATOR',
  quotaStartDate: 'QUOTA STARTING DATE',
  quotaHistoryDay: 'QUOTA HISTORY DAY',
  accendant: 'ACCENDANT'
}

const salesOrderManagement = {
  expectedArrivalInfo: 'FREIGHTER ETA INFORMATION',
  arrivalInfo: 'Arrival info',
  thirdPartyInfo: 'Third party info',
  salesConfirmation: 'SALES CONFIRMATION',
  saleOrderNo: 'Sales No.',
  purchaseNo: 'Purchases No.',
  saleDate: 'Sales date',
  checkDate: 'Check date',
  delivery: 'Delivery',
  estimatedTime: 'ETA',
  estimatedHours: 'ETA notices',
  estimatedDateTime: 'Date & Time',
  emailDatetime: 'E-mail Date & Time',
  anchorage: 'Anchorage',
  reporter: 'Reported By',
  arriveDetails: 'BARGE ARRIVAL OPERATIONAL DETAILS',
  bargeName: 'Barge name',
  bargeInCharge: 'Barge PIC',
  bargeContacts: 'Barge contacts',
  arriveDate: 'Arrival date',
  arrive: 'Arrival',
  vesselAnchored: 'Vessel\'s Anchored/Berthed',
  arriveShipSide: 'Alongside',
  hoseConnection: 'Hose connect',
  pumpOilStart: 'Start pump',
  pumpOilEnd: 'Stop pump',
  hoseDisconnected: 'Hose disconnect',
  bearOff: 'Cast off',
  deliveryQuantityMT: 'Delivered quantity',
  orderExecuteRecord: 'ORDER EXECUTION RECORD',
  associatedPurchaseConfirmation: 'Associated purchase confirmation',
  associatePurchaseConfirmation: 'Associate purchase confirmation',
  withoutApproval: 'Without the approval',
  resendMail: 'Resend mail',
  resendMailSuccess: 'Resend email successfully,please inform the seller to check the email'
}

const purchaseOrdersManagement = {
  PurchaseConfirmation: 'Confirmation of purchase',
  orderNumber: 'ORDER NUMBER',
  signedDate: 'DATE SIGNED',
  stemDate: 'STEM DATE',
  mode: 'MODE',
  queryType: 'ENQUIRY TYPE',
  orderMode: 'Order Mode',
  creditTerms: 'CREDIT TERMS',
  payableClause: 'PAYABLE CLAUSE',
  creditInformation: 'CREDIT INFORMATION',
  contractNumber: 'CONTRACT NOS',
  buyer: 'BUYER',
  buyerInCharge: 'BUYER IN CHARGE',
  buyerContact: 'BUYER CONTACT NOS',
  traderInCharge: 'TRADER IN CHARGE',
  traderContact: 'TRADER CONTACT NOS',
  vesselName: 'VESSEL NAME',
  country: 'COUNTRY',
  callPort: 'PORT OF CALL',
  laycanStartDate: 'LAYCAN DATE FROM',
  laycanEndDate: 'LAYCAN DATE TO',
  laydaysStart: 'LAYCAN DATE FROM',
  laydaysEnd: 'LAYCAN DATE TO',
  agent: 'AGENTS',
  agentContact: 'AGENTS CONTACT NOS',
  surveyor: 'SURVEYOR',
  surveyorContact: 'SURVEYOR CONTACT NOS',
  notary: 'NOTARY',
  supplier: 'SUPPLIER',
  deliveryMode: 'DELIVERY MODE',
  gtcs: 'GT&CS',
  businessMode: 'BUSINESS MODE',
  residualCreditLimit: 'RESIDUAL CREDIT LIMIT',
  mainContractNo: 'MASTER CONTRACT NUMBER',
  rfqst: 'THE START DATE SET BY THE TRADER TO MODIFY THE FINAL QUANTITY',
  rfqet: 'THE END DATE SET BY THE TRADER TO MODIFY THE FINAL QUANTITY',
  gradesAndSpecifications: 'REQUISITED GRADES & SPECS',
  grade: 'GRADES',
  sulfur: 'SULPHUR',
  specification: 'SPECS',
  minimumQuantity: 'QUANTITY MIN',
  maximumQuantity: 'QUANTITY MAX',
  buyPrice: 'BUY PRICE',
  finalQuantity: 'FINAL QUANTITY',
  declaredDate: 'DECLARED DATES',
  changeRecord: 'A RECORD OF THE CHANGE IN THE REPORTING DATE SET BY THE TRADER FOR THE FINAL QUANTITY',
  changeDate: 'DATE OF CHANGE',
  changeUser: 'USER OF CHANGE',
  note: 'NOTE',
  selectionMasterContract: 'Selection of master contracts',
  associatedMasterContract: 'ASSOCIATED MASTER CONTRACT',
  CASH_MODE: 'CASH_MODE',
  CREDIT_MODE: 'CREDIT_MODE',
  changeNumber: 'Change number',
  inputDateRange: 'Input date range',
  buyerSellerInfo: 'BUYER AND SELLER INFORMATION',
  bargeInfo: 'THE BARGE INFORMATION',
  confirmQuantity: 'Confirm quantity',
  globalValue: 'GLOBAL VALUE',
  floating: '+/-',
  sellerContact: 'SELLER CONTACT',
  checkUp: 'CHECK UP',
  checkDown: 'CHECK DOWN',
  vesselManager: 'SHIP ADMINISTRATOR FOR ORDER',
  firstConfirm: 'First confirm',
  finalConfirm: 'Final confirm'
}

const financingApplicationManagement = {
  newFinancingApplicationForm: 'FINANCING APPLICATION FORM',
  financingApplicationNo: 'FINANCING APPLICATION NO.',
  createDate: 'DATE CREATED',
  applicationDate: 'FINANCING APPLICATION DATE',
  buyer: 'BUYER',
  seller: 'SELLER',
  beginningTrader: 'BEGINNING TRADER',
  endingTrader: 'ENDING TRADER',
  bargeName: 'BARGE NAME',
  bargeInCharge: 'BARGE IN CHARGE',
  country: 'COUNTRY',
  callPort: 'PORT OF CALL',
  status: 'STATUS',
  directionalFinancing: 'DIRECTIONAL FINANCING',
  prospectiveFinancialInstitution: 'PROSPECTIVE FINANCIAL INSTITUTION',
  tradeTotalAmount: 'TOTAL AMOUNT OF TRADE',
  financingType: 'FINANCING TYPES',
  intendedFinancingAmount: 'AMOUNT OF INTENDED FINANCING',
  annualRate: 'ANNUAL INTEREST RATE OF INTENDED FINANCING',
  financePaymentDays: 'PAYMENT DAYS FINANCING (DAYS)',
  expirationDate: 'DEADLINE FOR FINANCING APPLICATIONS',
  financingTerms: 'FINANCING TERMS',
  financingAmount: 'FINANCING AMOUNT',
  finalFinancingAmount: 'FINAL FINANCING AMOUNT',
  interest: 'INTEREST',
  durationDays: 'DURATION DAYS',
  guarantee: 'GUARANTEE',
  dailyInterest: 'DAILY INTEREST',
  totalInterest: 'TOTAL INTEREST',
  lossProfitPerTon: 'LOSS OF PROFIT PER TON',
  associatedSalesConfirmation: 'ASSOCIATED SALES CONFIRMATION',
  financingSummary: 'OVERVIEW OF FINANCING INTENTION',
  oilSalesConfirmation: 'SALES CONFIRMATIONS OF OIL SUPPLIER',
  traderSalesConfirmation: 'SALES CONFIRMATIONS OF TRADER',
  traderPurchaseConfirmation: 'PURCHASE CONFIRMATIONS OF TRADER',
  makeOverNotification: 'NOTICE OF TRANSFER OF TITLE'
}

const fillingProgramManagement = {
  bargeFillingProgram: 'BARGE FILLING PROGRAM',
  bargeFillingProgramForm: 'BARGE FILLING PROGRAM FORM',
  newBargeFillingProgram: 'NEW BARGE FILLING PROGRAM FORM',
  number: 'NUMBER',
  fillingDateTime: 'LOADING DATE AND TIME',
  saleTerms: 'TERM OF SALES',
  loader: 'LOADER',
  traderInChargeContacts: 'CONTACTS',
  sellerOperationsPicContacts: 'CONTACTS',
  sellerOperationsPic: 'SELLER OPERATIONS PIC',
  termsConditions: 'TERMS & CONDITIONS',
  supplyDays: 'DAYS TO SUPPLY',
  loadingOperationDetails: 'LOADING OPERATIONAL DETAILS',
  assignBarge: 'ASSIGN BARGE',
  nominatedQuantity: 'NOMINATED QUANTITY',
  loadingQuantity: 'LOADING QUANTITY',
  nominationTendered: 'NOMINATION TENDERED BY',
  alongsideTime: 'ALONGSIDE DATETIME',
  hoseConnectTime: 'HOSE CONNECT DATETIME',
  pumpStartTime: 'START PUMP DATETIME',
  pumpStopTime: 'STOP PUMP DATETIME',
  hoseDisconnectTime: 'HOSE DISCONNECT DATETIME',
  castOffTime: 'CAST OFF DATETIME',
  internalRemark: 'REMARKS INTETNAL',
  // 6.3驳船装油确认单
  bargeFillingConfirmation: 'BARGE BUNKER LOADING CONFIRMATION',
  confirmationNo: 'CONFIRMATION NO',
  shipper: 'SHIPPER',
  vessel: 'VESSEL',
  BLNo: 'BL NO',
  destination: 'DESTINATION',
  metricTon: 'METRIC TON',
  customsLicenseNo: 'CUSTOMS LICENSE NUMBER',
  customsDocumentNo: 'CUSTOMS DOCUMENT NUMBER',
  berthNo: 'BERTH NO',
  products: 'PRODUCTS',
  shipSealNo: 'SHIP SEAL NO',
  tankNo: 'TANK NO',
  observationTemperature: 'OBSERVATION TEMPERATURE ℃',
  density15C: 'DENSITY @ 15℃',
  totalObservedVolume: 'TOTAL OBSERVED VOLUME',
  totalVolume15C: 'TOTAL VOLUME @15 ℃',
  VolumeInBbl60F: 'VOLUME IN BBL @60℉',
  quantityInMT: 'QUANTITY IN MT',
  USBbls60F: 'Volume in Bbls@ 60F',
  returnFundAmount: 'RETURN OF FUND AMOUNT',
  // 5.1原型修改后字段
  oilLoadingPlanNo: 'Ex-wharf Order Number',
  oilLoadingOrderNo: 'Barge loading order No.',
  oilSupplier: 'Oil supplier',
  oilSupplierInCharge: 'Oil supplier in charge',
  oilDepotAddress: 'Oil depot address',
  bargeNo: 'Barge No',
  oilLoadingInfo: 'BARGE LOADING INFORMATION',
  fillingDate: 'Barge loading date',
  actualLoadingQuantity: 'Actual loading',
  iotDatas: 'IOT bunker loading statistic',
  measuringRange: 'Measuring range',
  pumpStatus: 'Pump Status',
  valveStatus: 'Valve Status',
  stop: 'STOP',
  // close: 'CLOSE',
  time: 'TIME',
  cargoOilPumpTachometer: 'Cargo oil pump tachometer',
  pleaseSelectBarge: 'Please select a barge',
  percent: 'Percent',
  level: 'Level',
  temp: 'Temp',
  interval: 'Interval',
  update: 'update',
  updatedBy: 'Updated by',
  preUpdateInfo: 'Pre-Update Information',
  updatedInformation: 'Updated Information',
  // 质量流量计数据
  massFlowRate: 'Mass Flow Rate',
  density: 'Density',
  temperature: 'Temperature',
  massTotal: 'Mass Total',
  massInventory: 'Mass Inventory',
  volumeFlowRate: 'Volume Flow Rate',
  liveZero: 'Live Zero',
  driveGain: 'Drive Gain',
  rawTubeFrequency: 'Raw Tube Frequency',
  rightPickoffVoltage: 'Right Pickoff Voltage',
  leftPickoffVoltage: 'Left Pickoff Voltage',
  flowSignalOffsetAtZeroFlow: 'Flow Signal Offset At ZeroFlow',
  volumeTotal: 'Volume Total',
  APICorrectedVolumeTotal: 'API Corrected Volume Total',
  tempAPICorrectedDensity: 'Temp API Corrected Density',
  APIAvgCorrectedDensity: 'API AvgCorrected Density',
  APIAvgCorrectedTemperature: 'API AvgCorrected Temperature',
  currentCorrection: 'Current Correction',
  discreteInput1Status: 'Discrete Input 1 Status',
  maxAerationLimit: 'Max Aeration Limit',
  externalPressureInput: 'External Pressure Input',
  fieldVerificationZero: 'Field Verification Zero',
  deltaT: 'Delta T',
  statusReports: 'Status Reports'
}

const invoiceManagement = {
  invoice: 'Invoice',
  refuelingReceipt: 'Refueling receipt',
  invoiceApplication: 'Invoice application',
  supplierEmail: 'Supplier email',
  invoiceNo: 'INVOICE NO',
  invoiceDate: 'INVOICE DATE',
  dueDate: 'DUE DATE',
  invoiceStatus: 'INVOICE STATUS',
  invoiceTrader: 'INVOICE(TRADER)',
  companyAddress: 'COMPANY ADDRESS',
  companyRegistrationNo: 'COMPANY REGISTRATION NUMBER',
  taxInvoiceNo: 'TAX INVOICE NUMBER',
  companyPhone: 'COMPANY PHONE',
  email: 'E-mail',
  oldEmail: 'Old email',
  newEmail: 'New email',
  attn: 'ATTN',
  terms: 'TERMS',
  settlementCurrency: 'SETTLEMENT CURRENCY',
  taxRate: 'TAX RATE',
  commodityDetails: 'Commodity details',
  supplyDate: 'SUPPLY DATE',
  QTYMT: 'QTY/MT',
  unitPrice: 'UNIT PRICE',
  GST: 'GST',
  taxAmount: 'AMOUNT',
  invoiceInfo: 'INVOICE INFORMATION',
  exchangeRate: 'EXCHANGE RATE',
  receipt: 'Receipt',
  paymentReceiptRecord: 'PAYMENT RECEIPT RECORD',
  receiptDate: 'RECEIPT DATE',
  receiptAmount: 'RECEIPT AMOUNT',
  CREATED: 'CREATED',
  TRADER_CONFIRMED: 'TRADER CONFIRMED',
  TRADERS_CONFIRMED: 'TRADER CONFIRMED',
  FUND_PROVIDER_CONFIRMED: 'FUNDER CONFIRMED',
  FUNDER_CONFIRMED: 'FUNDER TBC',
  PENDING_COLLECTION: 'PENDING COLLECTION',
  RECEIVED_MONEY: 'RECEIVED MONEY',
  NO_CONFIRMED: 'NO CONFIRMED',
  CONFIRMED: 'CONFIRMED',
  Confirmed: 'Confirmed',
  CARGO_CONFIRMED: 'FREIGHTER CONFIRMED',
  invoiceConfirmTip: 'This operation will update the status of the invoice,whether to continue?',
  associatedInvoice: 'Associated invoice',
  associateInvoice: 'Associate invoice'
}

const paymentSlip = {
  paymentSlip: 'PAYMENT SLIP',
  paymentNo: 'Payment No',
  payer: 'Payer',
  payee: 'Payee',
  paymentReceiptDate: 'Payment receipt date',
  totalPaymentReceipt: 'Total payment receipt',
  paymentReceiptSlip: 'Payment receipt slip',
  receiptNo: 'Receipt No',
  receiptPaymentDate: 'Receipt payment date',
  associatedPaymentSlip: 'Associated paymentSlip',
  associatePaymentSlip: 'Associate paymentSlip',
  associatedFinancingApplication: 'Associated financing application',
  associateFinancingApplication: 'Associate financing application',
  payment_CREATED: 'CREATED',
  payment_CONFIRM: 'CONFIRMED',
  isConfirmTip: 'Is confirm?'

}

const bunkerSalesOrder = {
  bunkerSales: 'BUNKER SALES',
  bunkerSaleNo: 'Bunker sales No',
  mode: 'mode',
  businessModeSelect: 'Business Mode select',
  totalAmount: 'Total amount',
  FINANCING_NOTICE: 'FINANCING_NOTICE',
  FINANCING_NON_NOTICE: 'FINANCING_NON_NOTICE',
  NON_FINANCING: 'NON_FINANCING',
  associatedSalesApplication: 'Associated sales application',
  associateSalesApplication: 'Associate sales application',
  associatedBunkerSales: 'Associated bunker sales',
  associateBunkerSales: 'Associate bunker sales'
}

const shipOilSalesManagement = {
  bunkerSalesApplication: 'BUNKER SALES APPLICATION',
  modeSelection: 'MODE SELECTION',
  salesApplicationNo: 'SALES APPLICATION NO',
  agreeSwitchOnBargePump: 'AGREE TO SWITCH ON THE BARGE PUMP VALUE'
}

const salesApplicationForm = {
  salesRequisitionNo: 'SALES REQUISITION NO.',
  proposer: 'PROPOSER',
  financingInstitution: 'FINANCING INSTITUTION',
  applicationDate: 'APPLICATION DATE',
  oils: 'OILS'
}

const exWharf = {
  createNewOrder: 'Create New Order',
  createNewSchedule: 'Create New Schedule',
  linkContract: 'Link Contract',
  linkOrder: 'Link Order',
  purchaseContract: 'Purchase contract',
  salesContract: 'Sales contract',
  purchaseAndSalesContract: 'Purchase and sales contract',
  saveAsDraft: 'Save as draft',
  buyer: 'Buyer',
  seller: 'Seller'
}

const priceAndFormula = {
  manage: 'Manage',
  requiredField: 'Error. Required fields not filled in.'
}

export default {
  ...en,
  ...enterpriseRegister,
  ...menus,
  ...users,
  ...roles,
  ...masterContract,
  ...salesOrderManagement,
  ...whiteListManagement,
  ...purchaseOrdersManagement,
  ...financingApplicationManagement,
  ...fillingProgramManagement,
  ...invoiceManagement,
  ...paymentSlip,
  ...bunkerSalesOrder,
  ...shipOilSalesManagement,
  ...salesApplicationForm,
  ...exWharf,
  ...businessAttachment,
  ...priceAndFormula,
  ...bankInfo
}
