<template>
  <div
    id="app"
    data-theme="custom-cyan-theme"
    class="custom-cyan-theme">
    <router-view/>
    <network-detector></network-detector>
  </div>
</template>

<script>
import NetworkDetector from '@/components/NetworkDetector'

export default {
  components: { NetworkDetector },
  mounted () {
    // 如果之前有选择主题，则直接使用本地缓存的主题
    const theme = this.$store.state.currentTheme
    theme && this.onCheckedTheme(theme)
    const getWidth=document.body.clientWidth;
      this.$store.commit('setIsMobileScreen', getWidth<600);
    // this.setI18n()
    // const doing = (e) => {
    //   const a = window.screen.width
    //   const b = window.innerWidth
    //   document.body.style.zoom = (b / a).toString()
    // }
    // const throttle = (fun, delay) => {
    //   let last = +new Date()
    //   let deferTimer
    //   return args => {
    //     const now = +new Date()
    //     const difference = last + delay - now
    //     if (difference >= 0) {
    //       clearTimeout(deferTimer)
    //       deferTimer = setTimeout(() => {
    //         last = now
    //         fun(args)
    //       },   difference)
    //     } else {
    //       last = now
    //       fun(args)
    //     }
    //   }
    // }
    //
    // const throttleDo = throttle(doing, 100)
    // window.addEventListener('resize', throttleDo)
  },
  methods: {
    /**
     * 获取国家化数据并设置国际化配置
     */
    setI18n () {
      this.$request.post({
        url: this.$apis.queryI18n
      }).then(res => {
        if (res?.code === 1000) {
          const messages = res.data.records || []
          const cn = {}
          const en = {}
          messages.forEach(item => {
            if (item.key) {
              cn[item.key] = item.cn || item.key
              en[item.key] = item.en || item.key
            }
          })
          this.$i18n.setLocaleMessage('cn', cn)
          this.$i18n.setLocaleMessage('en', en)
        }
      })
    },
    /**
     * 设置主题
     * @param {Object} theme:主题配置对象
     */
    onCheckedTheme (theme) {
      const head = document.querySelector('head')
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = `static/themes/${theme.themeName}/index.css`
      // 添加主题css文件
      head.appendChild(link)
      // 设置导航菜单和顶部样式
      this.$store.commit('setCurrentTheme', theme)
      // 设置全局主题颜色
      const { style } = document.documentElement
      style.setProperty('--primary-color', theme.primaryColor)
      style.setProperty('--success-color', theme.successColor)
      style.setProperty('--warning-color', theme.warningColor)
      style.setProperty('--danger-color', theme.dangerColor)
      style.setProperty('--info-color', theme.infoColor)
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import "assets/styles/index";
</style>
